import Transition from "../components/transition"
import { useTranslation } from "gatsby-plugin-react-i18next"
import React from "react"
import PropTypes from "prop-types"
import { Helmet } from "react-helmet"
import * as styles from "./layout.module.scss"
try {
  require("smooth-scroll")('a[href*="#"]')
} catch (Any) {}
const Layout = ({ children, location }) => {
  const { pathname } = location

  const formatPathname = path => {
    const segments = path.split("/").filter(Boolean)

    return segments.length > 0 ? `${segments.join("/")}` : "schrott24_homepage"
  }

  const locizeKey = formatPathname(pathname)

  const { t, i18n } = useTranslation()

  return (
    <>
      <Helmet>
        <title>{t(`${locizeKey}_title`)}</title>
        <meta name="description" content={t(`${locizeKey}_description`)} />
        <meta name="keywords" content={t(`${locizeKey}_keywords`)} />
        <meta name="author" content="autor"></meta>
      </Helmet>

      <div className={styles.wrapper}>
        <main>
          {" "}
          <Transition location={location}>{children}</Transition>
        </main>
      </div>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
